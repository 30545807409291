<template>
  <div class="count">
    <h5 class="h5" @click="gotoBsc">0xe48285038800B86e4184Ca903D23E1e3FD04F615</h5>
    <div class="left">
      <div class="tips title">tip：</div>
      <div class="tips">1.The contract is open source</div>
      <div class="tips">2.The contract has been abandoned</div>
      <div class="tips">
        3.Each transaction will reward the initiator token, which is 0.1% of the transaction volume and will be deducted from the balance of the contract address
      </div>
      <div class="tips title">Token Economics：</div>
      <div class="tips">50% stored in the contract address</div>
      <div class="tips">40% Create Flow Pool</div>
      <div class="tips">5% Community Incentives</div>
      <div class="tips">5% team</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    gotoBsc(){
      window.open('https://bscscan.com/address/0xe48285038800b86e4184ca903d23e1e3fd04f615', '_blank');
    }
  },
  computed: {},
};
</script>
<style scoped lang="scss">
.left {
  margin-left: 54%;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  padding-top: 70px;
  .tips {
    margin-top: 10px;
  }
  .title {
    font-size: 26px;
    margin-top: 60px;
  }
}
.h5{
  text-align: center;
  font-size: 26px;
  color: #ffffff;
  cursor: pointer;
}
</style>